import { Button, Grid, Typography, Box, Tooltip, IconButton } from "@mui/material";
import { Form, useFormikContext,Formik } from "formik";
import TextInput from "../../../InputComponents/TextInput";
import Toggle from "../../../InputComponents/Toggle";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import DateComp from "../../../InputComponents/DateComp";
import moment from "moment";
import Radio from "../../../InputComponents/Radio";
import Select from "../../../InputComponents/Select";
import {
  claim_reason,
} from "../options/care";
import {previous_insure_company} from "../options/hdfc";
import { continuous_coverage_year_options } from "../options/star";
import { Add, Delete } from "@mui/icons-material";
import { useUploadPortingFile,useGetProposalDetailsQuery, useUpdateProposalFormData, } from '../../../../../../services/Proposal.service';
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProposalData } from "../../../../../../modules/proposal.slice";
import { allowOnlyNumbers } from "../../../../../../utils/inputUtils";
import * as yup from "yup";


const RequiredLabel = ({ label }) => (
  <span>
    {label} <Typography component="span" color="error">*</Typography>
  </span>
);

const PortabilityHdfc = () => {
  const navigate = useNavigate();
  const { values, submitForm, errors, setFieldValue, touched, } = useFormikContext();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const { isPortability } = useGetProposalDetailsQuery();
  const { saveForm } = useUpdateProposalFormData(() => { });
  // const isValidDate = moment(values.policy_expiry_date, "YYYY-MM-DD").isValid();
  const { upload, isUploadLoading } = useUploadPortingFile();
  const dispatch = useDispatch();

  const intialValues = {
    ...proposalData?.portability_details || {},
    // For Future Use
    // ...proposalData?.ckyc_details || {},
    app_url: proposalData?.portability_details?.app_url || '',
  }
  const validationSchema = yup.object().shape({
    policy_expiry_date: yup
    .string()
    .required()
    .test(
      "is-valid-date",
    "Policy Expiry Date is required",
      (value) => {
        return moment(value, "YYYY-MM-DD", true).isValid();
      }
    ),
    portability_reason: yup.string().required("Reason For Porting is required"),
    convert_ci_to_si: yup.string().required("This field is required"),
    previous_insurer: yup.string().required("Previous Insurer Name is required"),
    previous_product_name: yup.string().required("Product Name is required"),
    previous_policy_number: yup.string().required("Previous Policy Number is required"),
    policy_period: yup.string().required("Policy Period is required"),
    is_longer_exclusion: yup.string().required("is_longer_exclusion is required"),
    difference_in_waiting_period: yup.string().nullable().when("is_longer_exclusion", {
      is: "Y",
      then: yup.string().required("Longer Exclusion is required"),
    }),
    sameAsExpPolicyFl: yup.string().required("This field is required"),
    firstExpPolicyStartDt: yup.string().nullable().when("sameAsExpPolicyFl", {
      is: "N",
      then: yup.string().required("Date of the first expiring policy is required"),
    }),
    firstExpPolicyNum: yup.string().nullable().when("sameAsExpPolicyFl", {
      is: "N",
      then: yup.string().required("First expiring policy number is required"),
    }),
  members_covered_in_existing: yup
    .object({
      members: yup.array().of(yup.string()).min(1, "At least one member must be selected").required("Members are required")
    }).required("This field is required"),

});

  const handleSubmit = async (values) => {
    try {
      const submissionData = {
        portability_details: {
          ...values,
        },
      };
      await saveForm(submissionData);
      console.log("Submitted values:", submissionData);
      dispatch(setProposalData(submissionData));

      if (isPortability) {
        navigate({
          pathname: "/proposal-summary",
          search: window.location.search,
        });
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };
  return (
    <>
    <Formik
      initialValues={intialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
        {({
          submitForm, values, setFieldValue,
        }) => (
      <Form>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <DateComp 
            label={<RequiredLabel label="Policy Expiry Date" />}
             name="policy_expiry_date"/>
          </Grid>
          <Grid item md={6} xs={12}></Grid>

          {values?.policy_expiry_date && (
            <>
              <Grid item md={6} xs={12}>
                <TextInput
                  name="portability_reason"
                  label={"Reason For Porting"}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={12}>
                <Radio
                  label={<RequiredLabel label ="Do you want the cumulative bonus to be converted to an enhanced sum Insured?"/>}
                  name="convert_ci_to_si"
                  touched
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  options={previous_insure_company}
                  touched
                  name={`previous_insurer`}
                  label="Previous Insurer Name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name={`previous_product_name`}
                  touched
                  label="Product Name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name={`previous_policy_number`}
                  touched
                  label="Previous Policy Number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  options={[
                    {
                      OPTION_KEY: "1",
                      OPTION_VAL: "1 year",
                    },
                    {
                      OPTION_KEY: "2",
                      OPTION_VAL: "2 year",
                    },
                    {
                      OPTION_KEY: "3",
                      OPTION_VAL: "3 year",
                    },
                  ]}
                  touched
                  name={`policy_period`}
                  label="Policy Period"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput name={`addons`} touched label="Addons (if any)" />
              </Grid>
              <Grid item xs={12}>
                <Radio
                  name="is_longer_exclusion"
                  label= {<RequiredLabel label="Whether the PED exclusions/time bound exclusions have longer exclusion period then the existing policy. "/>}
                />
              </Grid>
              {values.is_longer_exclusion === "Y" && (
                <Grid item md={6} xs={12}>
                  <Select
                    name="difference_in_waiting_period"
                    label="Difference in Waiting period"
                    options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_item) => ({
                      OPTION_KEY: _item,
                      OPTION_VAL: `${_item} year`,
                    }))}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
              <Radio
                  name="sameAsExpPolicyFl"
                  label= {<RequiredLabel label="If First and Expiring policy dates are same."/>}
                />
              </Grid>
              {values?.sameAsExpPolicyFl === "N" && (
                <>
                <Grid item md={6} xs={12}>
                  <DateComp label="Date of the first expiring policy" name={`firstExpPolicyStartDt`}/>
                </Grid>
                <Grid  item md={6} xs={12}>
                  <TextInput 
                  label="First expiring policy number"
                  name={`firstExpPolicyNum`}/>
                </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Toggle
                  name="members_covered_in_existing"
                  label= {<RequiredLabel label="Which members are covered in existing policy?"/>}
                />
              </Grid>
              {values.members_covered_in_existing &&
                values.members_covered_in_existing.members?.map((item) => (
                  <>
                    <Grid item xs={12} md={6}>
                      <Select
                        name={`member_data.${item}.continous_coverage_year`}
                        touched
                        options={continuous_coverage_year_options}
                        label={<RequiredLabel label="Continous Coverage Year"/>}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DateComp
                         label={<RequiredLabel label="Date of enrollment" />}
                        name={`member_data.${item}.date_of_enrollment`}
                      />
                    </Grid>
                    {[
                      ...Array(
                        values.member_data?.[item]?.continous_coverage_year
                      ),
                    ]?.map((_, coverage_count) => (
                      <>
                        <Grid item xs={12}>
                          <TextInput

                            label={<RequiredLabel label={`Sum Insured in Year ${coverage_count + 1}`} />}
                            name={`member_data.${item}.prev_sum_insured.${coverage_count}`}
                          />
                        </Grid>
                      </>
                    ))}
                    <Grid item xs={12}>
                      <TextInput
                        name={`member_data.${item}.ncb_bonus_earned`}
                        label={<RequiredLabel label="No Claim Bonus Earned"/>}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                          {values.member_data?.[item]?.app_url ? (
                            <Box display="flex" flexDirection="column">
                              <Typography fontSize="16px" fontWeight="500" marginBottom="8px">
                                View Insurance Document
                              </Typography>
                              <Box display="flex" alignItems="center" justifyContent="space-between" padding="1px" border="1px solid #ccc" borderRadius="4px">
                                <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center" padding="4px">
                                  <a href={values.member_data?.[item]?.app_url} target="_blank" rel="noopener noreferrer">
                                    View Insurance Document
                                  </a>
                                </Box>
                                <Tooltip title="Clear">
                                  <IconButton onClick={() => setFieldValue(`member_data.${item}.app_url`, '')} aria-label="clear">
                                    <Close />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          ) : (
                            <TextInput
                              type="file"
                              name={`member_data.${item}.app_url`}
                              label={<RequiredLabel label="Upload Insurance Document"/>}
                              onChange={async (e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  const formData = new FormData();
                                  formData.append('file', file);
                                  try {
                                    const response = await upload(formData);
                                    const uploadedUrl = response?.data?.app_url;
                                    if (uploadedUrl) {
                                      setFieldValue(`member_data.${item}.app_url`, uploadedUrl);
                                    }
                                  } catch (error) {
                                    console.error('File upload failed:', error);
                                  }
                                }
                              }}
                            />
                          )}
                        </Grid>
                    <Grid item xs={12}>
                      <Radio
                        label={<RequiredLabel label="Did you make any claim?"/>}
                        touched
                        name={`member_data.${item}.is_claim_made`}
                        handleChange={(val) => {
                          if (val === "Y") {
                            setFieldValue(`member_data.${item}.claim_details`, [
                              {
                                claim_reason: "",
                                visit_date: "",
                                claim_amount: "",
                                claim_number: "",
                                payment_date: "",
                                select_insurer:"",
                              },
                            ]);
                          } else
                            setFieldValue(
                              `member_data.${item}.claim_details`,
                              []
                            );
                        }}
                      />
                    </Grid>
                    {values.member_data?.[item] &&
                      values.member_data?.[item]?.is_claim_made === "Y" &&
                      values.member_data?.[item]?.claim_details.map(
                        (claim_detail, claim_idx) => (
                          <>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                name={`member_data.${item}.claim_details.${claim_idx}.claim_reason`}
                                label="Reason for claim"
                                maxLength={50}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <DateComp
                                name={`member_data.${item}.claim_details.${claim_idx}.visit_date`}
                                label="Visit Date"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextInput
                                name={`member_data.${item}.claim_details.${claim_idx}.claim_amount`}
                                label="Claim Amount"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {" "}
                              <TextInput
                                name={`member_data.${item}.claim_details.${claim_idx}.claim_number`}
                                label="Claim Number"
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <TextInput
                            name={`member_data.${item}.claim_details.${claim_idx}.select_insurer`}
                            label="Hospital Id"
                            onInput={allowOnlyNumbers}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <DateComp
                                name={`member_data.${item}.claim_details.${claim_idx}.payment_date`}
                                label="Payment Date"
                              />
                            </Grid>
                            {values.member_data?.[item]?.claim_details?.length >
                              1 && (
                              <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"center"}
                              >
                                <Button
                                  color="error"
                                  variant="outlined"
                                  startIcon={<Delete />}
                                  onClick={() => {
                                    setFieldValue(
                                      `member_data.${item}.claim_details`,
                                      values.member_data?.[
                                        item
                                      ]?.claim_details.filter(
                                        (_item, curr_idx) => {
                                          return curr_idx !== claim_idx;
                                        }
                                      )
                                    );
                                  }}
                                >
                                  Remove
                                </Button>
                              </Grid>
                            )}
                          </>
                        )
                      )}
                    {values.member_data?.[item]?.claim_details?.length < 3 && (
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Button
                          color="success"
                          variant="outlined"
                          startIcon={<Add />}
                          onClick={() => {
                            setFieldValue(`member_data.${item}.claim_details`, [
                              ...values.member_data?.[item]?.claim_details,
                              {
                                claim_reason: "",
                                visit_date: "",
                                claim_amount: "",
                                claim_number: "",
                                payment_date: "",
                                select_insurer:"",
                              },
                            ]);
                          }}
                        >
                          Add
                        </Button>
                      </Grid>
                    )}
                  </>
                ))}
            </>
          )}
          <Grid item xs={12} justifyContent="center" display={"flex"} my={1}>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                submitForm();
                console.log(values,"valuesssssssss")
              }}
            >
              {"Proceed to Review & Pay"}
            </Button>
          </Grid>
        </Grid>
        </Form>
        )}
      </Formik>
    </>
  );
};

export default PortabilityHdfc;
