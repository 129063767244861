import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  setActiveProposal,
  setProposalData,
} from "../../../../modules/proposal.slice";
import {
  useGetBankDetailsByIFSC,
  useGetOccupationQuery,
  useGetPincodeMutation,
  useGetProposalDetailsQuery,
  useUpdateProposalFormData,
} from "../../../../services/Proposal.service";
import { genderOptions } from "../../options.utils";
import FormLabel from "../FormLabel";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import * as yup from "yup";
import DateComp from "../InputComponents/DateComp";
import moment from "moment";
import InputSideEffect from "../InputComponents/InputSideEffect";
import {
  allowOnlyNumbers,
  allowOnlyPanCard,
  allowOnlyAlphabets,
  regex,
  toCapitalize,
  allowAddress,
} from "../../../../utils/inputUtils";
import Height from "../InputComponents/Height";
import { useEffect, useState } from "react";
import {
  useVerifyCareCKYC,
  useVerifyPanCKYC,
} from "../../../../services/CKYC.service";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { useQueryClient } from "@tanstack/react-query";
import YesNo from "../InputComponents/YesNo";
import api from "../../../../api/api";
import { MembersInterface } from "../../../../modules/landing.slice";
import Swal from "sweetalert2";
import React from "react";
import CachedIcon from '@mui/icons-material/Cached';
import BankTextInput from "../InputComponents/BankTextInput";

const findIfMarried = (
  options: MembersInterface[] | undefined,
  selected:
    | "self"
    | "spouse"
    | "father"
    | "mother"
    | undefined
    | "son"
    | "daughter"
) => {
  if (
    selected === "self" &&
    options?.some((item) => item.relation === "spouse")
  )
    return true;

  if (selected === "father" || selected === "mother" || selected === "spouse") {
    return true;
  }
};
function getFirstLastName(fullName: string | undefined) {
  if (!fullName) return { first_name: null, last_name: null };
  const title = ["Mr", "Mrs", "Dr", "Miss", "Ms"];
  const nameParts = fullName.trim().replace("  ", " ").split(/\s+/);
  let first_index = 0;
  let first_name = nameParts[0];
  if (title.includes(first_name)) {
    first_index = 1;
    first_name = nameParts[1];
  }
  const last_name = nameParts.slice(first_index + 1).join(" ");
  return { first_name, last_name };
}
const ProposerForm = () => {
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const { verifyCKYC, isVerifyCKYC, resetVerify } = useVerifyPanCKYC();
  const { getUrlQuery } = useUrlQuery();
  const PROPOSAL_ID = getUrlQuery("proposal_id");
  const { enquiry, isProposalLoading, product, isCKYC, quote_data } =
    useGetProposalDetailsQuery();
  const { asyncSaveForm, saveForm, isSaving } = useUpdateProposalFormData();
  const { verifyCareCKYC } = useVerifyCareCKYC();
  const self = enquiry?.members?.find((member) => member.relation === "self");
  const spouse = enquiry?.members.find((item) => item.relation === "spouse");
  const { occupation_list } = useGetOccupationQuery();
  const { cityOptions, stateOptions, getCityState } = useGetPincodeMutation();
  const [ifscCode, setIfscCode] = useState('');
  const { mutate: fetchBankDetails, data: bankDetails, isLoading, isError, error } = useGetBankDetailsByIFSC();


  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [activeProposal]);
  const {
    cityOptions: commCityOptions,
    stateOptions: commStateOptions,
    isCityStateFetched: isCommCityStateFetched,
    getCityState: getCommCityState,
  } = useGetPincodeMutation();
  const queryClient = useQueryClient();
  const { members } = useGetProposalDetailsQuery();
  const selected_self_relation = members?.find(
    (item) => item.relation === "self"
  );
  const members_eligible_to_be_proposer = [
    {
      relation: "self",
      age: selected_self_relation ? selected_self_relation.age : 0,
      gender: selected_self_relation
        ? selected_self_relation.gender
        : undefined,
    } as MembersInterface,
    ...(members?.filter(
      (item) =>
        !item.relation.toLowerCase().includes("son") &&
        !item.relation.toLowerCase().includes("daughter") &&
        !item.relation.toLowerCase().includes("self")
    ) || []),
  ];
  useEffect(() => {
    if (ifscCode.length === 11) {
      fetchBankDetails(ifscCode);
    }
  }, [ifscCode, fetchBankDetails]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (activeProposal > 0 || isProposalLoading) return null;
  const initialData = {
    gender: members_eligible_to_be_proposer?.[0].gender || "",
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: proposalData?.proposer_details?.dob,
    pan_no: "",
    annual_income: "",
    email: "",
    mobile_no: enquiry?.mobile_no,
    alternate_no: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    pincode: enquiry?.pincode,
    pincode_value:cityOptions?.map((val)=>val.OPTION_VAL),
    city: "",
    state: "",
    comm_address_line_1: "",
    comm_address_line_2: "",
    comm_address_line_3: "",
    comm_pincode: "",
    comm_city: "",
    comm_state: "",
    BANK:"",
    STATE:"",
    BRANCH:"",
    CITY:"",
    ifscCode:"",
    marital_status: findIfMarried(
      members_eligible_to_be_proposer,
      members_eligible_to_be_proposer?.some((item) => item.relation === "self")
        ? "self"
        : members_eligible_to_be_proposer?.[0].relation
    )
      ? "Married"
      : "",
    is_comm_same_as_above: true,
    feet: "",
    inches: "",
    relation: members_eligible_to_be_proposer?.some(
      (item) => item.relation === "self"
    )
      ? "self"
      : members_eligible_to_be_proposer?.[0].relation,
    occupation: "",
    PEP_QUESTION_1: "N",
    NRI_QUESTION_1: "N",
  };

  const isReadOnly =
    proposalData?.proposer_details?.ckyc &&
    (product?.INSURANCE_SLUG.includes("hdfc_ergo_optimasecure") ||
      product?.INSURANCE_SLUG === "aditya_birla_health");
  return (
    <>
      <FormLabel label="Great! Let’s start with proposer details" />
      <Formik
        initialValues={
          proposalData?.proposer_details
            ? {
                ...proposalData?.proposer_details,
                dob: proposalData?.proposer_details?.dob,
                pincode_value:cityOptions?.map((val) => val.OPTION_VAL).join(", ")
              }
            : {
                ...initialData,
                dob: moment().subtract(
                  members_eligible_to_be_proposer?.find(
                    (item) => item.relation === initialData.relation
                  )?.age,
                  "years"
                ),
              }
        }
        onSubmit={async (values) => {
          console.log(values,'valuessssss')
          if(product?.INSURANCE_SLUG === "niva_bupa"){
            if (values.PEP_QUESTION_1 === "Y" || values.NRI_QUESTION_1 === "Y") {
              Swal.fire({
                text: "Please contact our customer service to continue your journey.",
              });
              return;
            }
          }
          if(product?.INSURANCE_SLUG === "aditya_birla_health"){
            if ( values?.NRI_QUESTION_1 === "Y") {
              Swal.fire({
                text: "Please contact our customer service to continue your journey.",
              });
              return;
            }
          }
        
          
          const same_comm = {
            comm_address_line_1: values.address_line_1,
            comm_address_line_2: values.address_line_2,
            comm_address_line_3: values.address_line_3,
            comm_pincode: values.pincode,
            comm_city: values.city,
            comm_state: values.state,
            pincode_value: cityOptions?.map((val) => val.OPTION_VAL).join(", ")
          };
          let panStatus: any = {};
          const { first_name, last_name } = getFirstLastName(
            panStatus?.data?.return_data?.name
          );
          dispatch(
            setProposalData({
              proposer_details: {
                ...values,
                ...(values.is_comm_same_as_above && same_comm),
                ...(first_name && last_name && { first_name, last_name }),
                pincode_value:cityOptions?.forEach((val)=>val.OPTION_VAL)
              },
              ckyc_details: {
                ...(proposalData?.ckyc_details || {}),
                ckycId: panStatus?.data?.return_data?.ckycId,
              },
              bank_details: {
                bankAccountNo: values.bankAccountNo || '',
                BANK: bankDetails?.BANK || '',
                BRANCH: bankDetails?.BRANCH || '',
                ifscCode : bankDetails?.IFSC || '',
                CENTRE: bankDetails?.CENTRE || '',
                STATE: bankDetails?.STATE || '',
              },
            })
          );

          await asyncSaveForm({
            proposer_details: {
              ...values,
              ...(values.is_comm_same_as_above && same_comm),
              ...(first_name && last_name && { first_name, last_name }),
            },
            ckyc_details: {
              ...(proposalData?.ckyc_details || {}),
              ckycId: panStatus?.data?.return_data?.ckycId,
            },
            from_screen: "proposal",
            bank_details: {
              bankAccountNo: values.bankAccountNo || '',
              BANK: bankDetails?.BANK || '',
              BRANCH: bankDetails?.BRANCH || '',
              ifscCode : bankDetails?.IFSC || '',
              CENTRE: bankDetails?.CENTRE || '',
              STATE: bankDetails?.STATE || '',
            },
          });

          if (
            product?.INSURANCE_SLUG === "star" ||
            product?.INSURANCE_SLUG.includes("hdfc_ergo_optimasecure")
          ) {
            panStatus = await verifyCKYC({
              pan_no: values?.pan_no,
              PROPOSAL_ID,
              INSURANCE_SLUG: product.INSURANCE_SLUG,
            });
            await api.post("api/RecalculateHealthPremium", {
              proposal_id: PROPOSAL_ID,
            });
          }
          if (product?.INSURANCE_SLUG === "aditya_birla_health") {
            await api.post("api/ABHICkycResponse", {
              proposal_id: PROPOSAL_ID,
            });
          }
          queryClient.invalidateQueries(["proposal"]);
          if (panStatus?.data?.CKYC_LINK && panStatus?.data?.IS_CKYC === "N") {
            window.open(panStatus?.data?.CKYC_LINK, "_self");
          }
          resetVerify();
        }}
        validationSchema={yup.object().shape({
          first_name: yup.string().required("First Name is required"),
          middle_name: yup.string().nullable(),
          last_name: yup.string().required("Last Name is required"),
          gender: yup.string().required("Gender is required"),
          dob: yup
            .string()
            .required("Date of birth is required")
            .test("dob", "Please Enter a valid Format", (val) => {
              return moment(val, "YYYY-MM-DD").isValid();
            })
            .test(
              "dob",
              `Age should be between ${product?.ADULT_MIN} and ${product?.ADULT_MAX}`,
              (val) => {
                return moment(val, "YYYY-MM-DD").isBetween(
                  moment()
                    .subtract(product?.ADULT_MAX, "years")
                    .subtract(1, "year"),
                  moment().subtract(product?.ADULT_MIN, "years")
                );
              }
            ),
          pan_no: yup
            .string()
            .required("PAN Number is required")
            .matches(regex.pan_no, "Please Enter a valid PAN Number"),
          annual_income: yup.string().required("Annual Income is required"),
          email: yup
            .string()
            .required("Email is required")
            .email("Please Enter a valid Email"),
          alternate_no: yup
            .string()
            .nullable()
            .matches(regex.mobile_no, "Please Enter a valid Mobile Number"),
          mobile_no: yup
            .string()
            .required("Mobile Number is required")
            .matches(regex.mobile_no, "Please Enter a valid Mobile Number"),
          address_line_1: yup
            .string()
            .required("Address is required")
            .max(60, "Address should not be more than 60 characters"),
          address_line_2: yup
            .string()
            .required("Address is required")
            .max(60, "Address should not be more than 60 characters"),
          pincode: yup
            .string()
            .required("Pincode is required")
            .matches(/^[1-9][0-9]{5}$/, "Please enter a valid pincode"),
          city: yup.string().required("City is required"),
          state: yup.string().required("State is required"),
          comm_address_line_1: yup.string().when("is_comm_same_as_above", {
            is: (val: boolean) => !val,
            then: yup
              .string()
              .required("Address is required")
              .max(60, "Address should not be more than 60 characters"),
          }),

          comm_address_line_2: yup.string().when("is_comm_same_as_above", {
            is: (val: boolean) => !val,
            then: yup
              .string()
              .required("Address is required")
              .max(60, "Address should not be more than 60 characters"),
          }),
          comm_pincode: yup.string().when("is_comm_same_as_above", {
            is: (val: boolean) => !val,
            then: yup
              .string()
              .required("Pincode is required")
              .matches(/^[1-9][0-9]{5}$/, "Please enter a valid pincode"),
          }),
          occupation: yup.string().required("Occupation is required"),
          marital_status: yup.string().required("Marital Status is required"),
          feet: yup
            .string()
            .required("Height is required")
            .test("feet", "Please enter a valid height", function (val) {
              return Number(val) > 0;
            }),

          inches: yup.string().required("Height is required"),
          weight: yup
            .string()
            .required("Weight is required")
            .test("weight", "Max Weight allowed is 180", (val) => {
              if (product?.INSURANCE_SLUG !== "star") return true;
              else return Number(val) <= 180;
            })
            .test(
              "weight",
              "Please enter a valid Weight",
              (val) => Number(val) > 0
            ),
          is_comm_same_as_above: yup.boolean(),
          relation: yup.string().required("Relation is required"),
        })}
      >
        {({ submitForm, values, setFieldValue }) => (
          <Form placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            <Grid container spacing={2}>
              <InputSideEffect
                effect={() => {
                  if (!proposalData?.proposer_details) {
                    const selectedRelation =
                      members_eligible_to_be_proposer?.find(
                        (item) => item.relation === values.relation
                      );
                    setFieldValue("gender", selectedRelation?.gender);
                    if (selectedRelation?.age) {
                      setFieldValue(
                        "dob",
                        moment()
                          .subtract(selectedRelation?.age, "years")
                          .format("YYYY-MM-DD")
                      );
                    } else setFieldValue("dob", null);
                    const isMarried = findIfMarried(
                      members_eligible_to_be_proposer,
                      values.relation
                    );
                    if (isMarried) {
                      setFieldValue("marital_status", "Married");
                    } else setFieldValue("marital_status", "");
                  }
                }}
                dependencies={[values.relation]}
              >
                <Grid item xs={12} md={6}>
                  <Select
                    options={
                      members_eligible_to_be_proposer?.map((item) => ({
                        OPTION_KEY: item.relation,
                        OPTION_VAL:
                          item.relation.charAt(0).toUpperCase() +
                          item.relation.slice(1),
                      })) || []
                    }
                    label="Who is Proposer?"
                    name="relation"
                  />
                </Grid>
              </InputSideEffect>
              <>{console.log(values)}</>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <TextInput
                  label="First Name"
                  name="first_name"
                  onInput={(e) => {
                    allowOnlyAlphabets(e);
                    toCapitalize(e);
                  }}
                  readOnly={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Middle Name (Optional)"
                  name="middle_name"
                  onInput={(e) => {
                    allowOnlyAlphabets(e);
                    toCapitalize(e);
                  }}
                  readOnly={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Last Name"
                  name="last_name"
                  onInput={(e) => {
                    allowOnlyAlphabets(e);
                    toCapitalize(e);
                  }}
                  readOnly={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  readOnly={
                    !!members_eligible_to_be_proposer?.find(
                      (item) => item.relation === values.relation
                    )?.gender
                  }
                  label="Gender"
                  name="gender"
                  options={genderOptions}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label="Marital Status"
                  name="marital_status"
                  readOnly={findIfMarried(
                    members_eligible_to_be_proposer,
                    values.relation
                  )}
                  options={[
                    {
                      OPTION_KEY: "Single",
                      OPTION_VAL: "Single",
                    },
                    {
                      OPTION_KEY: "Married",
                      OPTION_VAL: "Married",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateComp
                  label="Date of Birth"
                  name="dob"
                  maxDate={moment().subtract(18, "years")}
                  minDate={moment().subtract(100, "years")}
                  readOnly={isReadOnly}
                  defaultValue={proposalData?.proposer_details?.dob}
                />
              </Grid>{" "}
              <Grid item md={6} xs={12}>
                <Select
                  options={occupation_list || []}
                  label="Occupation"
                  name={`occupation`}
                />
              </Grid>
             { ( product?.INSURANCE_SLUG === "aditya_birla_health" && values.occupation === "Others") && (
              <Grid item xs={12} md={6}>
              <TextInput
                label="Other occupation"
                name="other_occupation"
                readOnly={isReadOnly}
              />
            </Grid>
             )}
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Pan Number"
                  name="pan_no"
                  onInput={allowOnlyPanCard}
                  readOnly={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Height />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Weight"
                  name={`weight`}
                  onInput={allowOnlyNumbers}
                  maxLength={3}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Annual Income"
                  name="annual_income"
                  onInput={allowOnlyNumbers}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.secondary" fontWeight="500">
                  Contact
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput label="Email" name="email" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Mobile No"
                  name="mobile_no"
                  onInput={allowOnlyNumbers}
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Alternate Mobile No"
                  name="alternate_no"
                  onInput={allowOnlyNumbers}
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.secondary" fontWeight="500">
                  Address
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Address Line 1"
                  name="address_line_1"
                  maxLength={60}
                  onInput={allowAddress}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Address Line 2"
                  name="address_line_2"
                  maxLength={60}
                  onInput={allowAddress}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Address Line 3"
                  name="address_line_3"
                  maxLength={60}
                  onInput={allowAddress}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputSideEffect
                  dependencies={[values.pincode, quote_data?.COMPANY_SLUG]}
                  effect={() => {
                    if (values.pincode && values.pincode.length === 6)
                      getCityState({
                        pincode: values.pincode,
                        company_alias: quote_data?.COMPANY_SLUG,
                      });
                  }}
                >
                  <TextInput
                    label="Pincode"
                    name="pincode"
                    onInput={allowOnlyNumbers}
                    maxLength={6}
                  />
                </InputSideEffect>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select options={cityOptions || []} label="City" name="city" />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputSideEffect
                  dependencies={[stateOptions]}
                  effect={() => {
                    if (stateOptions && stateOptions.length) {
                      setFieldValue("state", stateOptions[0].OPTION_VAL);
                    }
                  }}
                >
                  <TextInput label="State" name="state" readOnly />
                </InputSideEffect>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Is Communication Address same as above address?"
                  control={<Checkbox />}
                  onChange={(e, checked) => {
                    setFieldValue("is_comm_same_as_above", checked);
                  }}
                  checked={values.is_comm_same_as_above}
                />
              </Grid>
              {!values.is_comm_same_as_above && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Address Line 1"
                      name="comm_address_line_1"
                      maxLength={60}
                      onInput={allowAddress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Address Line 2"
                      name="comm_address_line_2"
                      maxLength={60}
                      onInput={allowAddress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Address Line 3"
                      name="comm_address_line_3"
                      maxLength={60}
                      onInput={allowAddress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputSideEffect
                      dependencies={[values.comm_pincode]}
                      effect={() => {
                        if (
                          values.comm_pincode &&
                          values.comm_pincode.length === 6
                        )
                          getCommCityState({
                            pincode: values.comm_pincode,
                            company_alias: quote_data?.COMPANY_SLUG,
                          });
                      }}
                    >
                      <TextInput
                        label="Pincode"
                        name="comm_pincode"
                        onInput={allowOnlyNumbers}
                        maxLength={6}
                      />
                    </InputSideEffect>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      options={commCityOptions || []}
                      label="City"
                      name="comm_city"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputSideEffect
                      dependencies={[commStateOptions]}
                      effect={() => {
                        if (commStateOptions && commStateOptions.length) {
                          setFieldValue(
                            "comm_state",
                            commStateOptions[0].OPTION_VAL
                          );
                        }
                      }}
                    >
                      <TextInput label="State" name="comm_state" readOnly />
                    </InputSideEffect>
                  </Grid>
                </>
              )}
           <> 
              {product?.INSURANCE_SLUG === 'manipal_cigna' && (
              <>
            <Grid item xs={12}>
               <Typography color="text.secondary" fontWeight="500">
                     Bank Details
                   {isLoading && (
                  <div className="center">
                  <CachedIcon className="loading-icon" />
                   </div>
                   )}
              </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Account Number"
              name="bankAccountNo"
              maxLength={60}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BankTextInput
              label="IFSC Code"
              name="ifscCode"
              maxLength={11}
              externalValue={ifscCode}
              onChange={(e) => setIfscCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BankTextInput
              label="Bank Name"
              name="BANK"
              externalValue={bankDetails?.BANK}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BankTextInput
              label="Branch Name"
              name="BRANCH"
              externalValue={bankDetails?.BRANCH}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BankTextInput
              label="Bank City"
              name="bankDetails?.CENTRE"
              externalValue={bankDetails?.CENTRE}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BankTextInput
              label="Bank State"
              name="bankDetails?.STATE}"
              externalValue={bankDetails?.STATE}
            />
          </Grid>
        </>
      )}
    </>
              {(product?.INSURANCE_SLUG === "niva_bupa" || product?.INSURANCE_SLUG === "aditya_birla_health") && (
                <>
                  <Grid item xs={12}>
                    <YesNo
                      label="Are you a PEP - Politically Exposed Person ?"
                      name="PEP_QUESTION_1"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <YesNo label="Are you an NRI?" name="NRI_QUESTION_1" />
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Box sx={{ position: "relative" }}>
                  <Button
                    size={isMobile ? "medium" : "large"}
                    variant="contained"
                    onClick={() => {
                      submitForm();
                    }}
                    disabled={isVerifyCKYC || isSaving}
                  >
                    {isSaving ? (
                      <CircularProgress size={"30px"} />
                    ) : (
                      <>
                        {isCKYC
                          ? "Proceed to CKYC Details"
                          : "Proceed to Insurer Details"}
                      </>
                    )}
                  </Button>
                  {isVerifyCKYC && (
                    <CircularProgress
                      size={isMobile ? 18 : 24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};



// CSS for loading animation
const styles = `
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
.loading-icon {
font-size: 24px; /* Adjust size as needed */
animation: spin 1s linear infinite;
}
.center {
display: flex;
justify-content: center;
align-items: center;
}
`;

// Inject styles into the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default ProposerForm;
