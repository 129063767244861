import { Autocomplete, Box, InputLabel, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React from "react";
export interface SelectOptions {
  OPTION_KEY: string;
  OPTION_VAL: string;
}
interface TextInputProps {
  label: string;
  name: string;
  error?: boolean;
  helperText?: string;
  options: SelectOptions[];
  readOnly?: boolean;
  isMultiple?: boolean;
}

const Select: React.FC<TextInputProps> = ({ name, label, ...props }) => {
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched, submitCount } = useFormikContext();
  console.log("isMultiple", props.isMultiple);
  const config = {
    ...field,
    ...props,
    getOptionLabel: (option: SelectOptions) => option.OPTION_VAL,
    isOptionEqualToValue: (option: SelectOptions, value: SelectOptions) =>
      option.OPTION_KEY === value.OPTION_KEY,
  };
  if (!props.isMultiple) {
    config.value = config.value
      ? props.options.find((item) => item.OPTION_KEY === config.value) || null
      : null;
  } else if (props.isMultiple) {
    config.value = config.value
      ? props.options.filter((item) => config.value.includes(item.OPTION_KEY))
      : [];
  }
  if (meta.error && (meta.touched || submitCount)) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <>
      <Autocomplete
        {...config}
        disableClearable
        size="small"
        fullWidth
        multiple={props.isMultiple}
        onChange={(event: any, value) => {
          if (props.isMultiple && value instanceof Array) {
            setFieldValue(
              name,
              value ? value.map((item: SelectOptions) => item.OPTION_KEY) : []
            );
          } else if (!(value instanceof Array) && value.OPTION_KEY)
            setFieldValue(name, value ? value.OPTION_KEY : "");
        }}
        onBlur={() => {
          setFieldTouched(name, true);
        }}
        renderInput={(params) => (
          <Box>
            <InputLabel
              sx={{
                fontSize: "15px",
                mb: 1,
                textOverflow: "unset",
                whiteSpace: "pre-line",
              }}
            >
              {label}
            </InputLabel>
            <TextField
              sx={{
                "& input": {
                  color: "#2D2C5F",
                  fontSize: "16px",
                  pt: "12px",
                },
                "& fieldset": {
                  height: "50px",
                },
              }}
              error={config.error}
              helperText={config.helperText}
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: props.readOnly,
              }}
            />
          </Box>
        )}
      />
    </>
  );
};

export default React.memo(Select);
