import { Box, InputLabel, TextField, TextFieldClasses } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
interface TextInputProps {
  label: string | React.ReactNode;
  name: string;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  maxLength?: number;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inlineValidate?: boolean;
  type?: string;
}

const TextInput: React.FC<TextInputProps> = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();
  const { value, ...remainingFields } = field;
  const config = {
    ...remainingFields,
    ...props,
    value: props.type !== "file" ? value : undefined,
  };

  if (meta.error && (meta.touched || submitCount)) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <>
      <Box>
        <InputLabel
          sx={{
            fontSize: "15px",
            mb: 1,
            textOverflow: "unset",
            whiteSpace: "pre-line",
          }}
        >
          {label}
        </InputLabel>
        <TextField
          size="small"
          fullWidth
          sx={{
            "& input": {
              color: "#2D2C5F",
              fontSize: "16px",
              pt: "12px",
            },
            "& fieldset": {
              height: "50px",
            },
          }}
          {...config}
          inputProps={{
            readOnly: props.readOnly,
            maxLength: props.maxLength,
          }}
        />
      </Box>
    </>
  );
};

export default React.memo(TextInput);
