import { Box, InputLabel, TextField, TextFieldClasses } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useEffect,useState } from "react";
interface BankTextInputProps {
  label: string | React.ReactNode;
  name: string;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  maxLength?: number;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inlineValidate?: boolean;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; 
  value?: string | number;
  externalValue?: string | number;
}

const BankTextInput: React.FC<BankTextInputProps> = ({ name, label,externalValue, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { submitCount } = useFormikContext();
  const { value, ...remainingFields } = field;
  const [inputValue, setInputValue] = useState<string | number>(
    externalValue || field.value
  );
  useEffect(() => {
    if (externalValue !== undefined) {
      setInputValue(externalValue);
    }
  }, [externalValue]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    field.onChange(event);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const config = {
    ...remainingFields,
    ...field,
    ...props,
    value: inputValue, 
    onChange: handleChange,
  };

  if (meta.error && (meta.touched || submitCount)) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <>
      <Box>
        <InputLabel
          sx={{
            fontSize: "15px",
            mb: 1,
            textOverflow: "unset",
            whiteSpace: "pre-line",
          }}
        >
          {label}
        </InputLabel>
        <TextField
          size="small"
          fullWidth
          sx={{
            "& input": {
              color: "#2D2C5F",
              fontSize: "16px",
              pt: "12px",
            },
            "& fieldset": {
              height: "50px",
            },
          }}
          {...config}
          inputProps={{
            readOnly: props.readOnly,
            maxLength: props.maxLength,
          }}
        />
      </Box>
    </>
  );
};

export default React.memo(BankTextInput);